import React from 'react'
import './Navbar.css'
import { Link } from 'react-router-dom'
import PetsIcon from '@mui/icons-material/Pets';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PersonIcon from '@mui/icons-material/Person';
import Tooltip from '@mui/material/Tooltip';
import LogoutIcon from '@mui/icons-material/Logout';
import {  signOut } from "firebase/auth";
import { auth } from '../Firebase';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';

export default function Navbar() {

  const handleLogout = () =>{
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }

  return (
    <div id='navbar'>
        <div style={{width: '100%', textAlign:'center', height: '70vh'}}>
          <Tooltip title="Calendar" placement="right">
            <Link to={'/'}><CalendarMonthIcon fontSize="large"  style={{margin:'20px 0px', color:'#fff'}}/></Link>
          </Tooltip>
          <Tooltip title="Walking Schedules" placement="right">
            <Link to={'/schedule'}><DirectionsWalkIcon fontSize="large"  style={{margin:'20px 0px', color:'#fff'}}/></Link>
          </Tooltip>
          <Tooltip title="My Dogs" placement="right">
            <Link to={'/my-dogs'}><PetsIcon fontSize="large" style={{margin:'20px 0px', color:'#fff'}}/></Link>
          </Tooltip>
          <Tooltip title="Documents" placement="right">
            <Link to={'/documents'}><UploadFileIcon fontSize="large" style={{margin:'20px 0px', color:'#fff'}}/></Link>
          </Tooltip>
          <Tooltip title="Profile" placement="right">
            <Link to={'/profile'}><PersonIcon fontSize="large" style={{margin:'20px 0px', color:'#fff'}}/></Link>
          </Tooltip>
        </div>
        
        <LogoutIcon fontSize="large" onClick={handleLogout} style={{margin:'20px 0px', color:'#fff'}}/>


    </div>
  )
}
