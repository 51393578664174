import React, { useEffect, useState} from 'react'
import { storage } from '../Firebase';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useAuth } from '../context/AuthContext'
import axios from 'axios'
import './Docs.css'
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default function Docs() {

  const { currentUser } = useAuth();
  const [fileName, setFileName] = useState("")
  const [file, setFile] = useState("")
  const [files, setFiles] = useState("")
  const [uploading, setUploading] = useState(false)
  const [selected, setSelected] = useState([])

  const [msg, setMsg] = useState("")
  const [dogs, setDogs] = useState([])
  const [dog, setDog] = useState("")
  const [email, setEmail] = useState("")

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(()=>{
    getFiles()
    getDogs()
  },[])

  const getDogs = () =>{
    axios.post(`${process.env.REACT_APP_API}/get-dogs-by-id`, {id: currentUser.uid})
    .then((res)=>{
      setDogs(res.data)
    })
    .catch((e)=>{
      console.log(e)
    })
  }

  const uploadFile = (file) =>{
    setFileName(file.name)

    const fileRef = ref(storage, `${currentUser.uid}/${file.name}`);

    setUploading(true)
      uploadBytes(fileRef, file).then((snapshot) => {

          getDownloadURL(fileRef)
          .then((url) => {
              setFile(url)
              setUploading(false)
          })
          .catch((error) => {
              console.log(error)
              setUploading(false)
          });
      });
  }

  const save = () =>{
    if(!fileName || !file){
      alert("Add file and name")
    } else {
      axios.post(`${process.env.REACT_APP_API}/upload-file`, {fileName, file, walkerId: currentUser.uid})
      .then((res)=>{
        console.log(res.data)
        getFiles()
        setFileName("");setFile("")
      })
      .catch((e)=>{
        console.log(e)
      })
    }
  }

  const getFiles = () =>{
    axios.post(`${process.env.REACT_APP_API}/get-files-by-id`, { walkerId: currentUser.uid})
      .then((res)=>{
        setFiles(res.data)
      })
      .catch((e)=>{
        console.log(e)
      })
  }

  const handleSelect = (e, file) =>{
    e.target.checked ? setSelected(prev=> [...prev, file]) : setSelected(prev=> prev.filter((selected)=> selected._id !== file._id))

  }

  const dogSelect = (choice) =>{

    if(choice){
      let info = dogs.filter((dog)=>dog._id == choice)
      console.log(info[0])
      setMsg(`Hi ${info[0].ownerName.split(" ")[0]}`)
      setEmail(info[0].email)
    } else {
      setMsg("");setEmail("")
    }
    

  }

  const sendEmail = () =>{
    if(!msg || !email){
      alert("Please add email and message")
    } else {
      let payload={msg, email, attachments: selected, walkerEmail: currentUser.email}
      console.log(payload)

      axios.post(`${process.env.REACT_APP_API}/send-email`, payload)
      .then((res)=>{
        console.log(res.data)
        setDog("");setEmail("");setMsg("");setSelected([]);
        handleClose()
      })
      .catch((e)=>{
        console.log(e)
      })
    }
  }

  const deleteFile = (file) =>{
    axios.post(`${process.env.REACT_APP_API}/delete-file`, {id: file._id})
      .then((res)=>{
        console.log(res.data)
        getFiles()
        
      })
      .catch((e)=>{
        console.log(e)
      })
  }

  return (
    <div>
      <h1>My documents</h1>
      <p>Upload and share documents with your customers</p>
      <div id='upload-container'>
     
        <input type='file' onChange={(e)=>uploadFile(e.target.files[0])}/>
        <button className='success-btn' onClick={save} disabled={uploading}>Save</button>
        {uploading && <p>Uploading...</p>}
      </div>

      {selected.length > 0 && <button className='success-btn' onClick={handleOpen}><SendIcon sx={{mr:1}}/>Share files</button>}
     
      {files.length < 1  ?<p>No files added</p> :
          files.map((file, i)=>{
            return <div className='doc-container' key={i}>
                <div style={{display:'flex'}}>
                  <input className='file-select' type='checkbox' onChange={(e)=>handleSelect(e, file)}/>
                  <a target='_blank' href={file.file}><h3>{file.fileName}</h3></a>
                </div>
                
                <DeleteForeverIcon sx={{mr:2}} onClick={()=>deleteFile(file)}/>
              </div>
          })}

    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2>Send email</h2>
          <label>Select dog if applicable</label>
          <select style={{width: '100%', margin: '10px 0px', height: 30}} value={dog} onChange={(e)=>{dogSelect(e.target.value); setDog(e.target.value)}} >
          <option value="">Select</option>
            {dogs.map((dog, i)=>{
              return <option key={i} value={dog._id}>{dog.dogName}</option>
            })}
          </select>
          <div>
            <label>To</label>
            <input style={{width: '100%', margin: '10px 0px', height: 30}} placeholder='Email' value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
          </div>
          <div>
            <label>Message</label>
            <textarea rows={18} style={{whiteSpace: 'pre-line', width:'100%', fontSize:16, padding:10}} value={msg} onChange={(e)=>setMsg(e.target.value)}/>
          </div>

          <button className='success-btn' onClick={sendEmail}>Send</button>

          <div>
            <label>Attachments:</label>

            {selected.map((doc,i)=>{
              return <p key={i}>{doc.fileName}</p>
            })}
          </div>
          
        </Box>
      </Modal>
  
    </div>
  )
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};