import React, {useState, useEffect} from 'react'
import { useAuth } from '../context/AuthContext'
import './Profile.css'
import axios from 'axios'

export default function Profile() {

    const {currentUser} = useAuth()

    useEffect(()=>{
        getUser()
    },[])



    const getUser = () =>{
        axios.post(`${process.env.REACT_APP_API}/get-user`, {id: currentUser.uid})
        .then((res)=>{
            console.log(res.data)
        })
        .catch((e)=>{
            console.log(e)
        })
    }

  return (
    <div>
        <h1>My profile</h1>
    </div>
  )
}
