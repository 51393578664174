import React, { useEffect, useState, useRef} from 'react'
import { useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import axios from 'axios'
import moment from 'moment-timezone'
import './ScheduleView.css'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import BusinessIcon from '@mui/icons-material/Business';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PetsIcon from '@mui/icons-material/Pets';

export default function EditRota() {
    const {currentUser} = useAuth()
    let {id} = useParams();
    let navigate = useNavigate();
    const [schedule, setSchedule] = useState("")
    const [open, setOpen] = useState(false);
    const [openWalk, setOpenWalk] = useState(false);
    const [otherWalks, setOtherWalks] = useState([])
    const [dogList, setDogList] = useState([]);
    const [dogs, setDogs] = useState([]);
    const [tempDogs, setTempDogs] = useState([]);
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedDog, setSelectedDog] = useState("");
    const [loading, setLoading] = useState(false)
    const [startStr, setStartStr] = useState("12:00");
    const [endStr, setEndStr] = useState("13:00");
    const [walks, setWalks] = useState([])
    const [price, setPrice] = useState("")

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleCloseWalk = () => setOpenWalk(false);
 
    //Intitial load
    useEffect(()=>{
        getSchedule()
        getDogs()
    }, [])

    useEffect(()=>{
        if(schedule){
            setWalks(schedule.walks)
        }
    }, [schedule])

    const getSchedule = () =>{
        axios.post(`${process.env.REACT_APP_API}/get-schedule-by-id`,{id} ,{params: {tenantId: currentUser.tenantId}, headers: {'AuthToken': currentUser.accessToken}})
        .then(function(res){
          setSchedule(res.data)
          setDogs(res.data.dogs)
          setTempDogs(res.data.dogs)
          setWalks(res.data.walks)
        })
        .catch((e)=>{
          console.log(e)
        })
    }

    const getDogs = () =>{
        axios.post(`${process.env.REACT_APP_API}/get-dogs-by-id`, {id: currentUser.uid})
        .then((res)=>{
          setDogList(res.data)
        })
        .catch((e)=>{
          console.log(e)
        })
      }
  
    const addDogs = (dog)=>{
     
        if (tempDogs.some(member => member.id === dog._id)) {
            return

          } else {
            setTempDogs(prev=> [...prev, {id: dog._id, dogName: `${dog.dogName}`, img: dog.img, email: dog.email, ownerName: dog.ownerName}])
          }
      }
    
    const removeDog = (dog) =>{
        if(window.confirm("Are you sure you want to remove this dog?")){
            let filteredStaff = schedule.dogs.filter(doggy => doggy.id !== dog.id)
            let remainingShifts = schedule.walks.filter((walk)=> walk.dogId !== dog.id)
            let filtered = schedule.walks.filter((walk)=> walk.dogId == dog.id)
            //Delete shifts
            filtered.forEach((element) => {

                axios.post(`${process.env.REACT_APP_API}/remove-walk`,{_id: element._id} ,{params: {tenantId: currentUser.tenantId}, headers: {'AuthToken': currentUser.accessToken}})
                .then(function(res){
                        console.log(res.data)
                })
                .catch((e)=>{
                console.log(e)
                })
            });
            //Remove walks from schedule
                axios.post(`${process.env.REACT_APP_API}/update-walk-and-dogs`,{id, walks:remainingShifts, dogs: filteredStaff} ,{params: {tenantId: currentUser.tenantId}, headers: {'AuthToken': currentUser.accessToken}})
                .then(function(res){
                        console.log(res.data)
                        getSchedule()
                })
                .catch((e)=>{
                console.log(e)
                })
            
        } else {
            return
        }
       
        
    }

      const saveDogs = () =>{
        setLoading(true)
        axios.post(`${process.env.REACT_APP_API}/save-dogs`,{id, dogs:tempDogs} ,{params: {tenantId: currentUser.tenantId}, headers: {'AuthToken': currentUser.accessToken}})
                .then(function(res){
                    getSchedule()
                        handleClose()
                        setLoading(false)
                })
                .catch((e)=>{
                console.log(e)
                })
      }

      const handleCellClick = (date, dog) =>{
          console.log(dog)
        let filtered = walks.filter((walk, i)=>{ 
            if(walk.dogId == dog.id && moment(walk.start).startOf('day').isSame(moment(date).startOf('day'))){
                walk.index=i
                return walk
            }
              
        })
           if(filtered.length > 0){
            setOtherWalks(filtered)
            setOpenWalk(true)
            setSelectedDate(date)
            setSelectedDog(dog)
            setStartStr("12:00");setEndStr("13:00")
          } else {
            setOtherWalks([])
            setOpenWalk(true)
            setSelectedDate(date)
            setSelectedDog(dog)
            setStartStr("12:00");setEndStr("13:00")
          }
          
      }


      const addWalk = async () => {
       
            setLoading(true)
            let payload = { title: selectedDog.dogName, dogId: selectedDog.id, dogName: selectedDog.dogName, img: selectedDog.img, email: selectedDog.email,
                date: new Date(selectedDate).toLocaleDateString('en-GB'), start: moment(`${moment(selectedDate).format('YYYY-MM-DD')}T${startStr}`).tz("Europe/London").format(),end: moment(`${moment(selectedDate).format('YYYY-MM-DD')}T${endStr}`).tz("Europe/London").format(),
                walkScheduleName: schedule.title, walkScheduleId: id, published: schedule.published ? true : false, walkerId: currentUser.uid, type:"Dog Walk",
                display: 'block', borderColor: '#ffaf26', backgroundColor: 'unset', textColor: '#ffaf26', price
                }
            
            axios.post(`${process.env.REACT_APP_API}/add-walk`, {id, payload, walks}, {params: {tenantId: currentUser.tenantId}, headers: {'AuthToken': currentUser.accessToken}})
                .then( function(res){
                    getSchedule()
                })
                .catch((e)=>console.log(e))
                .finally(()=>{
                    setLoading(false)
                    handleCloseWalk()
                    setStartStr("12:00"); setEndStr("13:00")
                })
    }

    const removeShift = (selectedShift) => {
       setLoading(true)
       let filtered = walks.filter((item)=> selectedShift._id !== item._id)
        axios.post(`${process.env.REACT_APP_API}/remove`, {id, walkId: selectedShift._id, walks: filtered}, {params: {tenantId: currentUser.tenantId}, headers: {'AuthToken': currentUser.accessToken}})
        .then( function(res){
            setLoading(false)
            getSchedule()
            handleCloseWalk()
            })
        .catch((e)=>{console.log(e);setLoading(false)})
        
}
    const updateShift = (selectedShift, start, end) => {
        let new_array = walks.map((walk) => walk._id == selectedShift._id ? {...walk, start : start, end: end} : walk);

        setLoading(true)
        axios.post(`${process.env.REACT_APP_API}/change-times`, {id, walks: new_array, walkId: selectedShift._id, start, end}, {params: {tenantId: currentUser.tenantId}, headers: {'AuthToken': currentUser.accessToken}})
        .then( function(res){
            setLoading(false)
            handleCloseWalk()
            getSchedule()
            toast.success("Successfully updated")
            })
        .catch((e)=>{console.log(e);setLoading(false)})
     
}

  
    const returnShift = (dog, date) =>{
        //Retrieve walks on a certain day belonging to a dog
        let filtered = walks.filter((walk)=> walk.dogId == dog.id && moment(walk.start).tz('Europe/London').startOf('day').isSame(moment(date).tz('Europe/London').startOf('day')))
        //Render single walk
        if(filtered.length === 1){
           
                return <div style={{display:'flex', flexDirection:'column', height:'100%', justifyContent:'space-evenly', alignItems: 'center'}} ><PetsIcon/>{moment(filtered[0].start).tz('Europe/London').format('HH:mm')} - {moment(filtered[0].end).tz('Europe/London').format('HH:mm')}</div>

           
        //Check for multiple walks
        } else if(filtered.length > 1){
         
                return <div style={{display:'flex',flexDirection:'column', height:'100%', justifyContent:'center', alignItems: 'center'}} ><PetsIcon/> {filtered.length} Walks</div>


        } else {
            return <div></div>
        }
    }

    const returnTotal = (date) =>{
        let filtered = walks.filter((walk)=> moment(walk.start).tz('Europe/London').startOf('day').isSame(moment(date).tz('Europe/London').startOf('day')))
        let amount = filtered.map((walk)=>{ return walk.price })
        let totals = amount.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
        if(filtered.length > 0){

            return <div style={{display:'flex', height:'100%', justifyContent:'center', alignItems: 'center'}} >£{totals}</div>

        } else {
            return <div style={{display:'flex', height:'100%', justifyContent:'center', alignItems: 'center'}}>£0</div>
        }
    }

    const returnDogTotal = (dog) =>{
        let filtered = walks.filter((walk)=> walk.dogId === dog.id )
        let amount = filtered.map((walk)=>{ return walk.price})
        let totals = amount.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
        if(filtered.length > 0){

            return <div style={{display:'flex', height:'100%', justifyContent:'center', alignItems: 'center'}} >£{totals}</div>

        } else {
            return <div style={{display:'flex', height:'100%', justifyContent:'center', alignItems: 'center'}}>£0</div>
        }
    }
    

    const publish = () =>{

        if(window.confirm("Are you sure you want to publish rota?")){
            setLoading(true)
            axios.post(`${process.env.REACT_APP_API}/publish`, {id, walks}, {params: {tenantId: currentUser.tenantId}, headers: {'AuthToken': currentUser.accessToken}})
            .then( function(res){
                getSchedule()
                toast.success(res.data)
                setLoading(false)
                })
            .catch((e)=>{console.log(e);setLoading(false)})
        } else {
            return
        }
        
    }

    const deleteRota = () =>{

        if(window.confirm("Are you sure you want to delete rota?")){
            setLoading(true)
            axios.post(`${process.env.REACT_APP_API}/delete-walk-schedule`, {_id: id}, {params: {tenantId: currentUser.tenantId}, headers: {'AuthToken': currentUser.accessToken}})
            .then( function(res){
                navigate('/schedule')
                toast.success(res.data)
                setLoading(false)
                })
            .catch((e)=>{console.log(e);setLoading(false)})
        } else {
            return
        }
        
    }

  return (
    <div>
        
        {schedule &&
        <>

        {console.log(schedule)}
        <h1>{schedule.title}</h1>
        <table id='rota-table'>
            <thead>
                <tr>
                    <th>Dogs</th>
                    {schedule.dates.map((date,i)=>{
                        return <th key={i} style={{textAlign:'center'}}>{moment(date).format('DD MMM ddd')}</th>
                    })}
                    <th  style={{textAlign:'center'}}>Total</th>
                </tr>
            </thead>
            <tbody>
                {dogs.map((dog, i)=>{
                    return ( 
                <tr key={i}>
                    <td>
                        <div className='rota-staff-table-container'>
                            <img className='rota-staff-img' src={dog.img}/>
                            <h3>{dog.dogName}</h3>
                            <PersonRemoveIcon color='error' onClick={()=>removeDog(dog)}/>
                        </div>
                    </td>
                        {schedule.dates.map((date,i)=>{
                            
                        return( 
                    <td  key={i}>
                        <div className='date-cell' onClick={()=>handleCellClick(date, dog)}>{returnShift(dog, date)}</div>
                    </td>)
                    })}

                    <td ><div style={{marginLeft: 12}} className='date-cell' >{returnDogTotal(dog)}</div></td>
                </tr>
                )})}
               
                 <tr>
                    <td  onClick={handleOpen}><div id='add-employee-btn'><PetsIcon sx={{color: '#fed42a', marginRight: 3}}/> <p>+/- Dogs</p></div></td>
                    {schedule.dates.map((date,i)=>{
                            
                            return( 
                        <td  key={i}>
                            <div className='date-cell' >{returnTotal(date)}</div>
                        </td>)
                        })}
                </tr>
                
            </tbody>
        </table>
        {!schedule.published && <>
            <button  id='publish-rota-btn' disabled={loading} onClick={publish}>Publish</button>
            <button id='delete-rota-btn' disabled={loading} onClick={deleteRota}>Delete</button>
        </>
        }
        </>
}
    {/*Dogs modal*/}
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <h2>Select dogs</h2>
          {dogList.length > 0 && dogList.map((employee, i)=>{
              return <div key={i} className={dogs.some(staff => staff.id === employee._id) || tempDogs.some(staff => staff.id === employee._id)? "staff-name-selected": 'staff-name-container'} onClick={()=>addDogs(employee)}><p>{employee.dogName}</p></div>
          })}
            <button disabled={loading} onClick={saveDogs}>Save</button>
            <button onClick={()=>{handleClose();setTempDogs(dogs)}}>Cancel</button>
        </Box>
      </Modal>
    {/*Walk modal*/}
      <Modal open={openWalk} onClose={handleCloseWalk} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
        {selectedDate && setSelectedDog && otherWalks.length> 0 && <>
                <h2>Add another walk</h2>

                <h3>{setSelectedDog.dogName}</h3>
                <h3>{moment(selectedDate).format('ddd, DD MMM')}</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Start</th>
                            <th>End</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {otherWalks.map((shift, i)=>{
                        console.log("Other", shift)
                        let start = shift.start;
                        let end = shift.end;
                       

                    return <tr key={i}>
                        <td ><input type='time' defaultValue={moment(shift.start).format('HH:mm')} onChange={(e)=> start= `${moment(shift.start).tz('Europe/London').format('YYYY-MM-DD')}T${e.target.value}`}/></td>
                        <td ><input type='time' defaultValue={moment(shift.end).format('HH:mm')} onChange={(e)=> end= `${moment(shift.end).tz('Europe/London').format('YYYY-MM-DD')}T${e.target.value}`}/></td>
                        <td ><button onClick={()=>updateShift(shift, start, end)}>Save</button></td>
                        <td ><button onClick={()=>removeShift(shift)}>Remove</button></td>
                    </tr> 
                })}
                    </tbody>
                </table>

                <h3>New walk</h3>
                
                <div className='row-container'>
                    <label htmlFor='start'>Start Time</label>
                    <input className='shift-date' type='time' id='start'  value={startStr} onChange={(e)=> setStartStr(e.target.value)} />
                </div>
                
                <div className='row-container'>
                    <label  htmlFor='finish'>End Time</label>
                    <input className='shift-date' type='time' id='finish' value={endStr} onChange={(e)=> setEndStr(e.target.value)}/>
                </div>
                <div className='row-container'>
                    <label  htmlFor='price'>Price (£)</label>
                    <input className='shift-date' type='number' id='price' value={price} onChange={(e)=> setPrice(e.target.value)}/>
                </div>

          <button disabled={loading || !startStr || ! endStr || !price} onClick={addWalk}>Add walk</button>
            </>}
            {selectedDate && setSelectedDog && otherWalks.length < 1 && <>
                <h2>Add walk</h2>
                <p>Walks will not be active until the rota is published</p>

                <h3>{setSelectedDog.dogName}</h3>
                <h3>{moment(selectedDate).format('ddd, DD MMM')}</h3>

             
                <div className='row-container'>
                    <label htmlFor='start'>Start Time</label>
                    <input className='shift-date' type='time' id='start'  value={startStr} onChange={(e)=> setStartStr(e.target.value)} />
                </div>
                
                <div className='row-container'>
                    <label  htmlFor='finish'>End Time</label>
                    <input className='shift-date' type='time' id='finish' value={endStr} onChange={(e)=> setEndStr(e.target.value)}/>
                </div>

                <div className='row-container'>
                    <label  htmlFor='price'>Price (£)</label>
                    <input className='shift-date' type='number' id='price' value={price} onChange={(e)=> setPrice(e.target.value)}/>
                </div>

          <button disabled={loading || !startStr || !endStr || !price} onClick={addWalk}>Save</button>
            </>}
        </Box>
      </Modal>
    </div>
  )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxHeight: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow:'scroll'
  };