import React, { useState, useEffect} from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import './Calendar.css'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import axios from 'axios'
import { useAuth } from '../context/AuthContext';

export default function Calendar() {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { currentUser} = useAuth()

  useEffect(()=>{
    getEvents()
    getWalks()
  },[])

  //form state
  const [title, setTitle] = useState("")
  const [type, setType] = useState("Dog walk")
  const [date, setDate] = useState("")
  const [from, setFrom] = useState("")
  const [to, setTo] = useState("")
  const [amount, setAmount] = useState("")
  const [events, setEvents] = useState([])
  const [walks, setWalks] = useState([])

  const handleSave = () =>{
    let payload = {title,type, date, from, to, amount, walkerId: currentUser.uid}
    axios.post(`${process.env.REACT_APP_API}/new-event`, payload)
    .then((res)=>{
      console.log(res.data)
      getEvents()
      handleClose()
    })
    .catch((e)=>{
      console.log(e)
    })
  }

  const getEvents = () =>{
    axios.post(`${process.env.REACT_APP_API}/get-events-by-id`, {id: currentUser.uid})
    .then((res)=>{
      setEvents(res.data)
    })
    .catch((e)=>{
      console.log(e)
    })
  }

  const getWalks = () =>{
    axios.post(`${process.env.REACT_APP_API}/get-published-walks`, {id: currentUser.uid})
    .then((res)=>{
      setWalks(res.data)
    })
    .catch((e)=>{
      console.log(e)
    })
  }


  return (
      <div id='calendar'>
        <button className='success-btn' onClick={handleOpen}>Add Event</button>
        <FullCalendar
                plugins={[ dayGridPlugin ]}
                initialView="dayGridMonth"
                eventSources={[events, walks]}
            />

    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          
          <h2>Add new entry</h2>

          <label>Entry title eg. Walk with Bonnie</label>
          <input className='calendar-input' value={title} onChange={(e)=>setTitle(e.target.value)}/>

          <label>Event type</label>
          <select className='calendar-input' value={type} onChange={(e)=>setType(e.target.value)}>
            <option value="Dog walk">Dog walk</option>
            <option value="Meet and Greet">Meet and Greet</option>
            <option value="Home visit">Home visit</option>
          </select>

          <label>Date</label>
          <input className='calendar-input' type='date' value={date} onChange={(e)=>setDate(e.target.value)}/>

          <label>From</label>
          <input className='calendar-input' type='time' value={from} onChange={(e)=>setFrom(e.target.value)}/>

          <label>To</label>
          <input className='calendar-input' type='time' value={to} onChange={(e)=>setTo(e.target.value)}/>

          <label>Amount (if applicable)</label>
          <input className='calendar-input' type='number' value={amount} onChange={(e)=>setAmount(e.target.value)}/>

          <div>
            <button className='success-btn' onClick={handleSave}>Create</button>
            <button className='cancel-btn' onClick={handleClose}>Cancel</button>
          </div>
       

        </Box>
      </Modal>
      </div>
   
  )
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display:'flex',
  flexDirection:'column',
  maxHeight: '90vh',
    overflow:'scroll'
};