import React, {useState, useEffect} from 'react'
import './Schedule.css'
import Box from '@mui/material/Box';
import { RRule } from 'rrule'
import moment from 'moment'
import Modal from '@mui/material/Modal';
import { useAuth } from '../context/AuthContext';
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export default function Schedule() {
  const {currentUser} = useAuth()
  let navigate = useNavigate()
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [title, setTitle] = useState("")
  const [dates, setDates] = useState([])
  const [dtstart, setDtstart] = useState("")
  const [until, setUntil] = useState("")
  const [unpublished, setUnpublished] = useState([])
  const [published, setPublished] = useState([])
  const [filtered, setFiltered] = useState([])
  const [current, setCurrent] = useState("Current")

  useEffect(()=>{
    getSchedules()
  },[])

  useEffect(()=>{
    if(dtstart && until){
      const rule = new RRule({
        freq: RRule.DAILY,
        dtstart: moment(`${dtstart}`).toDate(),
        until: moment(`${until}`).toDate(),
      })
      setDates(rule.all())
    } else {

    }

  },[dtstart, until])

  useEffect(()=>{

    if(current==="Current"){

      let filtered = published.filter( rota=>{  
        if(moment(rota.end).isAfter(moment())){
          return rota
        } 
      }  
        )
      console.log(filtered)
     setFiltered(filtered)

    } else if(current==="Past") {
      let filtered = published.filter( rota=>{  
        if(moment(rota.end).isSameOrBefore(moment())){
          return rota
        } 
      }  
        )
      console.log(filtered)
     setFiltered(filtered)
    }

  },[current, published])


  const createSchedule = ()=>{
    let payload = {title, dates, start:dtstart, end: until, dogs:[], walks:[], published: false, walkerId: currentUser.uid}
    axios.post(`${process.env.REACT_APP_API}/new-schedule`, {payload}, {params: {tenantId: currentUser.tenantId}, headers: {'AuthToken': currentUser.accessToken}})
    .then(function(res){
      getSchedules()
      handleClose()
    })
    .catch((e)=>{
      console.log(e)
    })
   }

   const getSchedules = ()=>{
    axios.post(`${process.env.REACT_APP_API}/get-schedules`,{id:currentUser.uid}, {params: {tenantId: currentUser.tenantId}, headers: {'AuthToken': currentUser.accessToken}})
    .then(function(res){
      let published = res.data.filter((item)=> item.published == true)
      let unpublished = res.data.filter((item)=> item.published == false)
      setPublished(published)
      setUnpublished(unpublished)
    })
    .catch((e)=>{
      console.log(e)
    })
   }

  return (
    <div style={{width:'100%'}}>
        <button id='new-rota-btn' onClick={handleOpen}>Create new walking schedule</button>
        {//<button id='existing-rota-btn' >Copy exisitng rota</button>
}
      <h2 style={{margin: '0.8rem 0'}}>Unpublished schedules ({unpublished.length})</h2>

      <table className='rota-table'>
        <thead>
          <tr style={{borderBottom: '2px solid grey'}}>
            <th>Schedule</th>
            <th>Date range</th>
            <th>Schedule overview</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
            {unpublished.map((schedule, i)=>{
              return <tr className='unpublished-row' key={i} onClick={()=>navigate(`/schedule/${schedule._id}`)} >
                <td><h3>{schedule.title}</h3></td>
                <td>{moment(schedule.start).format('ddd, DD MMM')} - {moment(schedule.end).format('ddd, DD MMM')}</td>
                <td><p>Dogs ({schedule.dogs.length})</p><p>Walks ({schedule.walks.length})</p></td>
                <td>{moment(schedule.createdAt).format('DD, MMM, YY')}</td>
                </tr>
            })}
        </tbody>
      </table>

      <h2 className='table-titles'>Published schedules</h2>

      <select value={current} onChange={(e)=>setCurrent(e.target.value)}>
        <option value={"Current"}>Current</option>
        <option value={"Past"}>Past</option>
      </select>

      <table className='rota-table'>
        <thead>
          <tr style={{borderBottom: '2px solid grey'}}>
            <th>Schedule</th>
            <th>Date range</th>
            <th>Schedule overview</th>
            <th>Created</th>

          </tr>
        </thead>
        <tbody>
            { filtered.map((schedule, i)=>{
           
                return <tr className='unpublished-row' key={i} onClick={()=>navigate(`/schedule/${schedule._id}`)} >
                <td><h3>{schedule.title}</h3></td>
                <td>{moment(schedule.start).format('ddd, DD MMM')} - {moment(schedule.end).format('ddd, DD MMM')}</td>
                <td><p>Dogs ({schedule.dogs.length})</p><p>Walks ({schedule.walks.length})</p></td>
                <td>{moment(schedule.createdAt).format('DD, MMM, YY')}</td>

                </tr>
          
            })
            }
        </tbody>
      </table>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
        <Box sx={style}>
          <h2>New draft schedule</h2>
          <label>Schedule Title</label>
          <input className='new-rota-input' value={title} onChange={(e)=>setTitle(e.target.value)}/>
{console.log(filtered)}
          <div>
            <label>Date from</label>
            <input className='new-rota-input' type='date' value={dtstart} onChange={(e)=>setDtstart(e.target.value)}/>
          </div>

          <div>
            <label>Date to</label>
            <input className='new-rota-input' type='date'  value={until} onChange={(e)=>setUntil(e.target.value)}/>
          </div>
          <div className='btn-container'>
            <button className={title && dtstart && until ? 'modal-confirm' : 'disabled'} onClick={createSchedule} disabled={!title || !dtstart || !until}>Create</button>
            <button className='modal-cancel' onClick={()=>{handleClose();setDtstart("");setUntil("");setTitle("")}}>Cancel</button>
          </div>
          

        </Box>
      </Modal>    

    </div>
  )
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};