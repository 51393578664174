import React, { useState, useEffect} from 'react'
import axios from 'axios'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import './MyDogs.css'
import { useAuth} from '../context/AuthContext'
import PetsIcon from '@mui/icons-material/Pets';
import { storage } from '../Firebase';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default function MyDogs() {

    const { currentUser} = useAuth()

    const [breeds, setBreeds] = useState([])
    const [dogId, setDogId] = useState("")
    const [dogs, setDogs] = useState([])
    const [dogName, setDogName] = useState("")
    const [breed, setBreed] = useState("")
    const [ownerName, setOwnerName] = useState("")
    const [number, setNumber] = useState("")
    const [email, setEmail] = useState("")
    const [img, setImg] = useState("")
    const [address, setAddress] = useState("")
    const [postcode, setPostcode] = useState("")
    const [uploading, setUploading] = useState(false)

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openDog, setOpenDog] = useState(false);
    const handleCloseDog = () => setOpenDog(false);
  
    useEffect(()=>{
        getBreeds()
        getDogs()
    },[])

    const getBreeds = () =>{
       axios.get("https://api.thedogapi.com/v1/breeds", {params: { api_key :"live_SGPEGeoNb0c74I0tgAAdF45vJo0muE9gg9JW6T9bY7GhWVkcpE9pLDM32MbG8e2i"}})
       .then((res)=>{
           setBreeds(res.data)
       })
       .catch((e)=>{
           console.log(e)
       })
    }

    const newDog = () =>{
        let payload = {
            dogName, ownerName, breed, number, email, address, postcode,
            walkerId:currentUser.uid,
            img: "https://firebasestorage.googleapis.com/v0/b/dog-walking-22eb2.appspot.com/o/dog.png?alt=media&token=e09ea200-0c4c-49aa-96c6-744715337f13"
        }

        axios.post(`${process.env.REACT_APP_API}/new-dog`, payload)
            .then((res)=>{
            getDogs()
            handleClose()
            })
            .catch((e)=>{
            console.log(e)
            })
    }

    const getDogs = () =>{
        axios.post(`${process.env.REACT_APP_API}/get-dogs-by-id`, {id: currentUser.uid})
        .then((res)=>{
          setDogs(res.data)
        })
        .catch((e)=>{
          console.log(e)
        })
      }

    
  const uploadFile = (file) =>{

    const fileRef = ref(storage, `${currentUser.uid}/${dogId}`);

    setUploading(true)
      uploadBytes(fileRef, file).then((snapshot) => {

          getDownloadURL(fileRef)
          .then((url) => {
              setImg(url)
              setUploading(false)
          })
          .catch((error) => {
              console.log(error)
              setUploading(false)
          });
      });
  }

  const handleDogClick = (dog)=>{
      setOpenDog(true)
      setDogName(dog.dogName);setOwnerName(dog.ownerName);setImg(dog.img);
      setEmail(dog.email);setAddress(dog.address);setPostcode(dog.postcode);
      setNumber(dog.number); setBreed(dog.breed); setDogId(dog._id)

  }

  const update = () =>{
    let payload = {
        dogName, ownerName, breed, number, email, address, postcode,
        walkerId:currentUser.uid,
        img
    }

    axios.post(`${process.env.REACT_APP_API}/update-dog`, {dogId,payload})
        .then((res)=>{
        getDogs()
        handleCloseDog()
        })
        .catch((e)=>{
        console.log(e)
        })
  }

  return (
    <div style={{textAlign: 'center'}} >
        <button className='success-btn' onClick={handleOpen}><PetsIcon sx={{mr:1}}/>Add new dog</button>
        

        <table id='dog-table'>
            <thead>
                <tr id="thead-tr">
                    <th></th>
                    <th>Dog Name</th>
                    <th>Number</th>
                    <th>Email</th>
                    <th>Address</th>
                </tr>
            </thead>
            <tbody>
                {dogs.length < 1 ? <td style={{textAlign:'center', padding: 20}} colSpan={5}>No dogs added</td> :
                dogs.map((dog, i)=>{
                    return <tr key={i} onClick={()=>handleDogClick(dog)}>
                        <td><img className='dog-img' src={dog.img} alt={dog.dogName}/></td>
                        <td>{dog.dogName}</td>
                        <td>{dog.number}</td>
                        <td>{dog.email}</td>
                        <td>{dog.address}</td>
                    </tr>
                })
                }
            </tbody>
        </table>

        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2>Add new dog</h2>


        <div>
            <label>Dog name</label>
            <input className='dog-input' value={dogName} onChange={(e)=>setDogName(e.target.value)}/>
        </div>

          
         <label>Breed</label>
          <select className='dog-input' value={breed} onChange={(e)=>setBreed(e.target.value)}>
          <option value="Mixed">Mixed</option>
            {breeds.map((breed, i)=>{
                return <option key={i} value={breed.name}>{breed.name}</option>
            })}
        </select>

        <div>
            <label>Owner name</label>
            <input className='dog-input' value={ownerName} onChange={(e)=>setOwnerName(e.target.value)}/>
        </div>

        <div>
            <label>Contact Number</label>
            <input className='dog-input' value={number} onChange={(e)=>setNumber(e.target.value)}/>
        </div>

        <div>
            <label>Contact Email</label>
            <input className='dog-input' value={email} onChange={(e)=>setEmail(e.target.value)}/>
        </div>

        <div>
            <label>Address</label>
            <textarea className='dog-input' style={{height:80, resize:'none'}} value={address} onChange={(e)=>setAddress(e.target.value)}/>
        </div>

        <div>
            <label>Postcode</label>
            <input className='dog-input' value={postcode} onChange={(e)=>setPostcode(e.target.value)}/>
        </div>

        <button className='success-btn' onClick={newDog}>Save Dog</button>
        </Box>
      </Modal>
        {/*View dog*/}
      <Modal
        open={openDog}
        onClose={handleCloseDog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
           
          

            <img className='dog-img' src={img}/>
            <input type='file' onChange={(e)=>uploadFile(e.target.files[0])}/>

        <div>
            <label>Dog name</label>
            <input className='dog-input' value={dogName} onChange={(e)=>setDogName(e.target.value)}/>
        </div>

          
         <label>Breed</label>
          <select className='dog-input' value={breed} onChange={(e)=>setBreed(e.target.value)}>
          <option value="Mixed">Mixed</option>
            {breeds.map((breed, i)=>{
                return <option key={i} value={breed.name}>{breed.name}</option>
            })}
        </select>

        <div>
            <label>Owner name</label>
            <input className='dog-input' value={ownerName} onChange={(e)=>setOwnerName(e.target.value)}/>
        </div>

        <div>
            <label>Contact Number</label>
            <input className='dog-input' value={number} onChange={(e)=>setNumber(e.target.value)}/>
        </div>

        <div>
            <label>Contact Email</label>
            <input className='dog-input' value={email} onChange={(e)=>setEmail(e.target.value)}/>
        </div>

        <div>
            <label>Address</label>
            <textarea className='dog-input' style={{height:80, resize:'none'}} value={address} onChange={(e)=>setAddress(e.target.value)}/>
        </div>

        <div>
            <label>Postcode</label>
            <input className='dog-input' value={postcode} onChange={(e)=>setPostcode(e.target.value)}/>
        </div>

        <button className='success-btn' onClick={update}>Update</button>
     
        </Box>
      </Modal>
    </div>
  )
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflow:'scroll',
    textAlign:'center'
  };