import React from "react";
import Calendar from "./components/Calendar";
import './App.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import UI from "./components/UI";
import Docs from "./components/Docs";
import MyDogs from "./components/MyDogs";
import Login from "./components/Login";
import RequireAuth from './components/RequireAuth'
import { AuthProvider} from './context/AuthContext'
import Profile from "./components/Profile";
import Schedule from "./components/Schedule";
import ScheduleView from "./components/ScheduleView";

const App = () =>{
    return (
        <AuthProvider>
        <BrowserRouter>
            <Routes>

                    <Route path="/login" element={<Login />}/>
                    
                    <Route element={<RequireAuth  />} >
                        <Route  element={<UI />} >
                            <Route index element={<Calendar />} />
                            <Route path="/schedule" element={<Schedule />}/>
                            <Route path="/schedule/:id" element={<ScheduleView />} />
                            <Route path="/documents" element={<Docs />} />
                            <Route path="/my-dogs" element={<MyDogs />} />
                            <Route path="/profile" element={<Profile />} />
                        </Route>
                    </Route>
                    
            </Routes>

            
        </BrowserRouter>
        </AuthProvider>
    )
}

export default App