import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from './Navbar'

export default function UI() {
  return (
    <div>
        <Navbar />

        <main style={{width: 'calc(100vw - 70px)', position:'absolute', left: 70, top:0, padding:'1rem'}}>
            <Outlet/>
        </main>
    </div>
  )
}
