import React, { useState } from 'react'
import './Login.css'
import { Link, useNavigate} from 'react-router-dom'
import {  signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../Firebase'
import logo from '../assets/icon.png'

export default function Login() {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  let navigate = useNavigate()

 const handleLogin = () =>{
     if(!email || !password){
      alert("Please input all fields")
    } else {
      signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        console.log(user)
        navigate('/')      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
       alert(errorMessage)
      });
    }
    
  }

  return (
    <div id='login-page'>

      <div id='logo-container'>
        <img src={logo} style={{width: 100}}/>
        <h1 id='logo-name'>Go Fetch</h1>
      </div>
      <div id='credentials-container'>
        <input className='login-input' placeholder='Email' value={email} onChange={(e)=>setEmail(e.target.value)}/>
        <input className='login-input' type='password' placeholder='Password' value={password} onChange={(e)=>setPassword(e.target.value)}/>
        <button id='login-btn' onClick={handleLogin}>Login</button>

        <Link to='/reset-password' style={{fontWeight:'bold'}}>Forgotten Password?</Link>
      </div>
      
    </div>
  )
}
